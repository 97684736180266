import { render, staticRenderFns } from "./AddIcon.vue?vue&type=template&id=b2d4427e&scoped=true&"
import script from "./AddIcon.vue?vue&type=script&lang=js&"
export * from "./AddIcon.vue?vue&type=script&lang=js&"
import style0 from "@/css/icons.css?vue&type=style&index=0&id=b2d4427e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2d4427e",
  null
  
)

export default component.exports