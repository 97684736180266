var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"nav-bar"}},[_c('div',{attrs:{"id":"top-nav-bar"}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/home"}},[_c('p',{attrs:{"id":"logo-text"}},[_vm._v("rfrl")])]),_c('router-link',{attrs:{"to":_vm.profileLink},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('a',{attrs:{"href":href},on:{"click":navigate}},[_c('img',{style:({borderColor: _vm.activeColor(isActive)}),attrs:{"id":"nav-profile-picture","src":_vm.currentProfile.photo}})])]}}])}),_c('div',{attrs:{"id":"nav-icon-links"}},[_c('router-link',{staticClass:"icon-link",attrs:{"to":"/questions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-icon-link',{attrs:{"navigate":navigate,"href":href}},[_c('question-icon',{attrs:{"iconColor":_vm.activeColor(isActive)}})],1)]}}])}),_c('router-link',{staticClass:"icon-link",attrs:{"to":"/clients"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-icon-link',{attrs:{"navigate":navigate,"href":href}},[_c('client-icon',{attrs:{"iconColor":_vm.activeColor(isActive)}})],1)]}}])}),_c('router-link',{staticClass:"icon-link",attrs:{"to":"/tutors"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-icon-link',{attrs:{"navigate":navigate,"href":href}},[_c('tutor-icon',{attrs:{"iconColor":_vm.activeColor(isActive)}})],1)]}}])}),_c('router-link',{staticClass:"icon-link",attrs:{"to":"/chat"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-icon-link',{attrs:{"navigate":navigate,"href":href}},[_c('message-icon',{attrs:{"iconColor":_vm.activeColor(isActive)}})],1)]}}])})],1)],1),_c('div',{attrs:{"id":"bottom-nav-bar"}},[_c('div',{attrs:{"id":"nav-icon-links"}},[_c('router-link',{staticClass:"icon-link",attrs:{"to":"/settings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('nav-icon-link',{attrs:{"navigate":navigate,"href":href}},[_c('settings-icon',{attrs:{"iconColor":_vm.activeColor(isActive)}})],1)]}}])}),_c('button',{staticClass:"btn-no-style icon-link",on:{"click":_vm.logoutUser}},[_c('sign-out-icon',{attrs:{"iconColor":_vm.activeColor(false)}})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }