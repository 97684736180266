<template>
  <!-- eslint-disable vue/max-len -->
  <svg width="180" height="159" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1">

  <g>
    <title>{{name}}</title>
    <g stroke="null" id="svg_34">
    <path stroke="null" id="svg_1" fill="#f2f2f2" d="m115.13658,115.30819l0,34.5734l-80.17452,0c-9.13481,0 -16.56698,-7.75504 -16.56698,-17.2867s7.43216,-17.2867 16.56698,-17.2867l80.17452,0z"/>
    <path stroke="null" id="svg_2" fill="#606c38" d="m117.10588,149.61565a1.2313,1.33028 0 0 1 -1.23081,1.32975l-82.34105,0a16.86207,18.21752 0 1 1 0,-36.43505l82.34105,0a1.23081,1.32975 0 0 1 0,2.65949l-82.34105,0a14.40045,15.55803 0 1 0 0,31.11606l82.34105,0a1.2313,1.33028 0 0 1 1.23081,1.32975z"/>
    <path stroke="null" id="svg_3" fill="#ccc" d="m115.13658,123.28667l-86.15656,0a0.24616,0.26595 0 1 1 0,-0.5319l86.15656,0a0.24616,0.26595 0 0 1 0,0.5319z"/>
    <path stroke="null" id="svg_4" fill="#ccc" d="m115.13658,129.66945l-86.15656,0a0.24616,0.26595 0 1 1 0,-0.5319l86.15656,0a0.24616,0.26595 0 0 1 0,0.5319z"/>
    <path stroke="null" id="svg_5" fill="#ccc" d="m115.13658,136.05224l-86.15656,0a0.24616,0.26595 0 1 1 0,-0.5319l86.15656,0a0.24616,0.26595 0 0 1 0,0.5319z"/>
    <path stroke="null" id="svg_6" fill="#ccc" d="m115.13658,142.43502l-86.15656,0a0.24616,0.26595 0 1 1 0,-0.5319l86.15656,0a0.24616,0.26595 0 0 1 0,0.5319z"/>
    <path stroke="null" id="svg_7" fill="#f2f2f2" d="m177.09974,121.81622l-20.0781,26.92163l-62.43032,-54.34679c-7.11311,-6.1921 -8.39673,-17.26872 -2.86133,-24.69084s15.82633,-8.42288 22.93943,-2.23078l62.43032,54.34679z"/>
    <path stroke="null" id="svg_8" fill="#ccc" d="m158.70954,149.86564a1.2313,1.33028 0 0 1 -1.73064,0.20114l-64.11735,-55.81538a16.86207,18.21752 0 0 1 21.15923,-28.37125l64.11735,55.81539a1.23081,1.32975 0 1 1 -1.54447,2.07089l-64.11735,-55.81539a14.40045,15.55803 0 1 0 -18.07029,24.22946l64.11735,55.81539a1.2313,1.33028 0 0 1 0.18617,1.86975z"/>
    <path stroke="null" id="svg_9" fill="#ccc" d="m172.46635,128.0289l-67.08842,-58.40174a0.24616,0.26595 0 1 1 0.3089,-0.41418l67.08841,58.40176a0.24616,0.26595 0 1 1 -0.30889,0.41418l0.00001,-0.00001z"/>
    <path stroke="null" id="svg_10" fill="#ccc" d="m168.75961,132.99905l-67.08841,-58.40175a0.24616,0.26595 0 0 1 0.30889,-0.41418l67.08841,58.40175a0.24616,0.26595 0 1 1 -0.30889,0.41418z"/>
    <path stroke="null" id="svg_11" fill="#ccc" d="m165.05288,137.9692l-67.08841,-58.40176a0.24616,0.26595 0 0 1 0.30889,-0.41418l67.08841,58.40175a0.24616,0.26595 0 1 1 -0.3089,0.41418l0,0.00001z"/>
    <path stroke="null" id="svg_12" fill="#ccc" d="m161.34615,142.93934l-67.08841,-58.40174a0.24616,0.26595 0 1 1 0.3089,-0.41418l67.08841,58.40174a0.24616,0.26595 0 1 1 -0.30889,0.41418z"/>
    <path stroke="null" id="svg_13" fill="#ccc" d="m181.32595,151.21134l-182.6519,0a0.24616,0.26595 0 0 1 0,-0.5319l182.6519,0a0.24616,0.26595 0 0 1 0,0.5319z"/>
    <polygon stroke="null" id="svg_14" fill="#ffb8b8" points="81.65044617652893,111.67428588867188 78.83633637428284,111.67398071289062 77.4979498386383,99.94670104980469 81.65142273902893,99.94725036621094 81.65044617652893,111.67428588867188 "/>
    <path stroke="null" id="svg_15" fill="#2f2e41" d="m76.82602,110.8053l5.42732,0a0,0 0 0 1 0,0l0,3.69189a0,0 0 0 1 0,0l-8.84452,0a0,0 0 0 1 0,0l0,0a3.4172,3.69189 0 0 1 3.4172,-3.69189z"/>
    <polygon stroke="null" id="svg_16" fill="#ffb8b8" points="52.899954080581665,111.67428588867188 50.085901498794556,111.67398071289062 48.74750733375549,99.94670104980469 52.90095353126526,99.94725036621094 52.899954080581665,111.67428588867188 "/>
    <path stroke="null" id="svg_17" fill="#2f2e41" d="m48.07569,110.8053l5.42732,0a0,0 0 0 1 0,0l0,3.69189a0,0 0 0 1 0,0l-8.84452,0a0,0 0 0 1 0,0l0,0a3.4172,3.69189 0 0 1 3.4172,-3.69189z"/>
    <path stroke="null" id="svg_18" fill="#2f2e41" d="m62.29964,24.68522l0,-1.32976a7.93871,8.57686 0 0 1 7.93871,-8.57686l0.00001,0a7.93871,8.57686 0 0 1 7.93871,8.57686l0,1.32976a5.72326,6.18332 0 0 1 -5.72326,6.18332l-4.43091,0a5.72326,6.18332 0 0 1 -5.72327,-6.18332z"/>
    <circle stroke="null" id="svg_19" fill="#ffb8b8" r="5.88565" cy="24.7941" cx="70.23835"/>
    <path stroke="null" id="svg_20" fill="#ffb8b8" d="m98.48954,59.9461"/>
    <path stroke="null" id="svg_21" fill="#ffb8b8" d="m37.35208,47.24369a2.30826,2.49381 0 0 1 3.51526,0.4461l5.18284,-1.05919l1.36396,3.24579l-7.33862,1.38472a2.32076,2.50731 0 0 1 -2.72343,-4.01743l0,0.00001z"/>
    <path stroke="null" id="svg_22" fill="#ccc" d="m65.52478,35.43971l0.06778,0.11096l-8.49893,6.04885l-14.34096,4.94753a0.86349,0.9329 0 0 0 -0.59374,0.99419l0.31285,2.91125a0.86175,0.93102 0 0 0 1.0364,0.80277l13.49697,-3.12564a4.87546,5.26737 0 0 0 1.81458,-0.86313l9.54808,-7.33937a2.4665,2.66477 0 0 0 1.02364,-2.37958a2.45551,2.6529 0 0 0 -3.7989,-1.99686l-0.06777,-0.11096z"/>
    <path stroke="null" id="svg_23" fill="#2f2e41" d="m78.88659,108.34637a1.11227,1.20168 0 0 1 -1.01854,-0.71838l-12.90131,-34.4407a0.3692,0.39888 0 0 0 -0.69137,0.04207l-10.52545,33.52922a1.10794,1.197 0 0 1 -1.44782,0.76889l-3.52956,-1.43a1.10499,1.19381 0 0 1 -0.71313,-0.98523c-1.58785,-14.94432 12.25886,-52.66587 12.39895,-53.04506l0.03876,-0.10505l12.62245,3.0166l0.02626,0.03091c5.03592,5.93542 9.17529,43.37154 9.95398,50.81592a1.10245,1.19107 0 0 1 -0.73139,1.26235l-3.11548,1.19041a1.09705,1.18523 0 0 1 -0.36635,0.06804z"/>
    <path stroke="null" id="svg_24" fill="#ccc" d="m68.011,56.19291c-3.05821,0 -6.46775,-0.65876 -7.51724,-3.37774l-0.02416,-0.06246l0.03263,-0.05792c0.82839,-1.46844 1.92326,-3.9734 1.33478,-4.44361c-1.15923,-0.92602 -1.72175,-2.44939 -1.67181,-4.52789c0.10842,-4.51205 2.95406,-8.51804 7.08105,-9.96842l0.00006,0a31.42061,33.94635 0 0 1 3.51098,-0.97666a5.97698,6.45744 0 0 1 4.95587,1.32221a6.03746,6.52278 0 0 1 2.23907,5.01862c0.04327,4.82189 -0.64383,11.53776 -4.16311,16.1485a1.09487,1.18288 0 0 1 -0.64814,0.40723a30.08661,32.50512 0 0 1 -5.12998,0.51813z"/>
    <path stroke="null" id="svg_25" fill="#ffb8b8" d="m66.87339,56.01236a2.3523,2.54139 0 0 1 2.87479,-1.77233a2.31575,2.5019 0 0 1 0.3564,0.14262l3.9353,-3.86925l2.73682,1.99203l-5.62769,5.3804a2.34815,2.53691 0 0 1 -2.66179,1.21981a2.31249,2.49838 0 0 1 -1.61383,-3.09327z"/>
    <path stroke="null" id="svg_26" fill="#ccc" d="m73.41652,56.06149a1.10468,1.19348 0 0 1 -0.70044,-0.26998l-1.55191,-1.37013a1.10771,1.19676 0 0 1 0.02686,-1.87632l6.50892,-5.39339a0.37006,0.39981 0 0 0 0.06977,-0.55709l-4.01743,-5.73817a3.27027,3.53315 0 0 1 0.21846,-4.55152l0,0a3.26113,3.52328 0 0 1 4.40326,-0.38049l0.02542,0.02896l4.19509,6.48979a3.73985,4.04048 0 0 1 -0.08865,6.43213l-8.42995,6.95156a1.10954,1.19873 0 0 1 -0.6594,0.23466z"/>
    <path stroke="null" id="svg_27" fill="#2f2e41" d="m64.88296,22.95654l0,-3.2409l5.3554,-2.52652l5.10647,2.52652l0,3.2409a0.49232,0.5319 0 0 1 -0.49232,0.5319l-9.47722,0a0.49232,0.5319 0 0 1 -0.49232,-0.5319z"/>
    <path stroke="null" id="svg_28" fill="#2f2e41" d="m69.9925,15.20853c-0.78742,-2.72293 0.86632,-5.84352 3.31108,-6.92133s5.36963,-0.32439 7.43252,1.45631s3.34278,4.44784 4.02095,7.20488s0.80689,5.63196 0.93209,8.48148c3.00372,0.5961 5.04701,4.44515 4.00647,7.5472s-4.89189,4.64315 -7.5517,3.0218s-3.43018,-5.97977 -1.51054,-8.54593a6.49223,7.01411 0 0 0 -7.55415,-10.8799c-1.48037,0.66884 -3.33784,-1.06895 -3.08672,-1.36449z"/>
    <path stroke="null" id="svg_29" fill="#606c38" d="m36.24179,34.67154a25.71125,27.77805 0 0 1 -10.42449,-2.46665a1.10097,1.18947 0 0 1 -0.65279,-1.09146l0,-9.68532a1.10896,1.1981 0 0 1 1.10773,-1.19677l19.93909,0a1.10898,1.19812 0 0 1 1.10773,1.19677l0,9.68534a1.10101,1.18952 0 0 1 -0.65278,1.09146l0,0a25.71126,27.77805 0 0 1 -10.42449,2.46664z"/>
    <path stroke="null" id="svg_30" fill="#606c38" d="m36.23906,26.82526a1.10901,1.19816 0 0 1 -0.43045,-0.0935l-15.81144,-7.20712a1.10764,1.19668 0 0 1 0.03768,-2.22147l15.81165,-6.48058a1.1037,1.19242 0 0 1 0.7905,0.00182l15.59977,6.48056a1.10776,1.1968 0 0 1 0.03792,2.21746l-15.59994,7.207a1.10945,1.19863 0 0 1 -0.43569,0.09583z"/>
    <circle stroke="null" id="svg_31" fill="#3f3d56" r="1.4378" cy="31.26822" cx="27.01073"/>
    <path stroke="null" id="svg_32" opacity="0.2" d="m52.3286,18.49942l-15.60001,7.20701a0.98463,1.06378 0 0 1 -0.76986,0.00201l-15.81143,-7.20691a0.96606,1.04372 0 0 1 -0.47081,-0.44708a0.98391,1.063 0 0 0 0.47081,1.51088l15.81143,7.20691a0.98463,1.06378 0 0 0 0.76986,-0.00201l15.60001,-7.20701a0.98394,1.06303 0 0 0 0.46636,-1.50879a0.96662,1.04432 0 0 1 -0.46636,0.44499z"/>
    <path stroke="null" id="svg_33" fill="#3f3d56" d="m27.13483,31.26198a0.24616,0.26595 0 0 0 0.24616,-0.26595l0,-8.89904l8.1187,-3.19542a0.24619,0.26598 0 0 0 0.00733,-0.5319l-8.1187,3.19542a0.48289,0.5217 0 0 0 -0.35284,0.15297a0.4888,0.52809 0 0 0 -0.14682,0.37893l0,8.89904a0.24617,0.26596 0 0 0 0.24616,0.26595z"/>
    </g>
  </g>
  </svg>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'education-icon',
};
</script>

<style scoped src="@/css/icons.css">
</style>
