<template>
<div>
    <textarea></textarea>
</div>
</template>

<script>
export default {
  name: 'About',
};
</script>
